body {
  margin: 0;
  padding: 0;
  zoom: 85%;
  font-family:  'Lato', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.dynamic-component::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.dynamic-component {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
button{
  text-transform: none !important;
}
.cbRaMarkdownPreview .react-mde{
  border: none !important;
  border-radius: 0 !important;
}
.mde-preview .mde-preview-content img{
  width: 100%;
  margin: 15px auto;
}
.MuiDrawer-paper {
  height: 100% !important;
}
#main-content {
  min-height: 100vh;
}
.layoutContainer .raPageContent .MuiPaper-root{
  padding: 15px;
  width: 0;
}

/* .react-resizable-handle .react-resizable-handle-se {
  color: rgba(0, 0, 0, 0.87) !important;
  border: 1px solid #eee !important;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  background-clip: padding-box !important;
  background-color: #fff !important;
} */

.relatedModCard {
  margin: auto auto 15px auto;
  padding: 5px 0;
  border: 1px solid #eee;
  cursor: pointer;
  width: 90%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.justLogItTimeline .MuiTimelineItem-root::before{
  display: none;
}
.RAimageHolderBox{
  width: 75%;
  min-height: 230px;
  max-height: 230px;
}
.RAimageHolderBox img{
  width: 100% !important;
  margin: 0 !important;
  max-height: unset !important;
}
@media (max-width: 768px){
  .RAimageHolderBox{
    width: 45%;
  }
}
/*! Lightning Design System 2.17.1 */
/*! ONLY ICONS */
.slds-icon_container,
.slds-icon__container{
  display:inline-block;
  border-radius:var(--slds-c-icon-radius-border, var(--sds-c-icon-radius-border, 0.25rem));
  line-height:1;
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, transparent));
}
.related-record-save-button-massCreate{
  display: none !important;
}
.slds-icon_container_circle,
.slds-icon__container_circle,
.slds-icon_container--circle,
.slds-icon__container--circle{
  /*! @css-var-fallback border-radius */
  --slds-c-icon-radius-border:50%;
  padding:0.5rem;
}
[dir=rtl] .slds-icon_flip{
  -webkit-transform:scaleX(-1);
          transform:scaleX(-1);
}

[class*=slds-icon-action-]{
  padding:0.5rem;
  border-radius:50%;
}

.slds-icon-action-new-custom18{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #4dca76));
}

.slds-icon-action-new-custom29{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #bdd25f));
}

.slds-icon-action-edit-groups{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #34becd));
}

.slds-icon-action-new-custom9{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #6b9ee2));
}

.slds-icon-action-log-a-call{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #48c3cc));
}

.slds-icon-action-new-custom19{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #3abeb1));
}

.slds-icon-action-filter{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #fd90b5));
}

.slds-icon-action-user-activation{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #54698d));
}

.slds-icon-action-opportunity-competitor{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #fcb95b));
}

.slds-icon-action-canvas{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #8199af));
}

.slds-icon-action-change-record-type{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #54698d));
}

.slds-icon-action-new-notebook{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #e6d478));
}

.slds-icon-action-docusign{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #5080db));
}

.slds-icon-action-share-link{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #7a9ae6));
}

.slds-icon-action-add-file{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #7e8be4));
}

.slds-icon-action-edit-relationship{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #1dccbf));
}

.slds-icon-action-notebook{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #e6d478));
}

.slds-icon-action-new-lead{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f88962));
}

.slds-icon-action-new-custom-object{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #a7d44d));
}

.slds-icon-action-new-account{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #7f8de1));
}

.slds-icon-action-question-post-action{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #32af5c));
}

.slds-icon-action-share-file{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #baac93));
}

.slds-icon-action-default-custom-object{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #8199af));
}

.slds-icon-action-opportunity-team-member{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #fcb95b));
}

.slds-icon-action-add-photo-video{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #00cdc0));
}

.slds-icon-action-sort{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #fab9a5));
}

.slds-icon-action-call{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #1fcaa0));
}

.slds-icon-action-concur{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #4cc3c7));
}

.slds-icon-action-reject{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #00c6b7));
}

.slds-icon-action-share-poll{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #699be1));
}

.slds-icon-action-following{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #7dcf64));
}

.slds-icon-action-defer{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #ef7ead));
}

.slds-icon-action-opportunity-line-item{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #fcb95b));
}

.slds-icon-action-social-post{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #ea74a2));
}

.slds-icon-action-share-post{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #65cae4));
}

.slds-icon-action-view-relationship{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #3c97dd));
}

.slds-icon-action-adjust-value{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #47b46c));
}

.slds-icon-action-upload{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #54698d));
}

.slds-icon-action-remove-relationship{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #ef6e64));
}

.slds-icon-action-freeze-user{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #54698d));
}

.slds-icon-action-new-person-account{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #7f8de1));
}

.slds-icon-action-bug{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #ef6e5d));
}

.slds-icon-action-apex{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #696e71));
}

.slds-icon-action-new-opportunity{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #fcb95b));
}

.slds-icon-action-fallback{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #9895ee));
}

.slds-icon-action-dial-in{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #8b9ae3));
}

.slds-icon-action-approval{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #00c6b7));
}

.slds-icon-action-change-owner{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #54698d));
}

.slds-icon-action-new-task{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #4bc076));
}

.slds-icon-action-priority{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #fbb439));
}

.slds-icon-action-remove{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #54698d));
}

.slds-icon-action-web-link{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #56aadf));
}

.slds-icon-action-leave-group{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f39e58));
}

.slds-icon-action-manage-perm-sets{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #54698d));
}

.slds-icon-action-close{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #ef6e64));
}

.slds-icon-action-google-news{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f5675b));
}

.slds-icon-action-announcement{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #fe8f60));
}

.slds-icon-action-back{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #0dc2d9));
}

.slds-icon-action-new-custom90{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #22a48a));
}

.slds-icon-action-download{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #54698d));
}

.slds-icon-action-new-custom80{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #659ad5));
}

.slds-icon-action-new-custom91{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #bf7b66));
}

.slds-icon-action-search{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #48adeb));
}

.slds-icon-action-new-event{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #eb7092));
}

.slds-icon-action-new-custom70{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #e769b4));
}

.slds-icon-action-new-custom81{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #da627f));
}

.slds-icon-action-new-custom92{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #517e82));
}

.slds-icon-action-refresh{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #54698d));
}

.slds-icon-action-share-thanks{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #e9696e));
}

.slds-icon-action-update{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #81b4d6));
}

.slds-icon-action-email{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #95aec5));
}

.slds-icon-action-join-group{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #779ef2));
}

.slds-icon-action-new-custom60{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #bf5a88));
}

.slds-icon-action-new-custom71{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #e36ee3));
}

.slds-icon-action-new-custom82{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #d15b97));
}

.slds-icon-action-new-custom93{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #904d4c));
}

.slds-icon-action-edit{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #1dccbf));
}

.slds-icon-action-quote{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #88c651));
}

.slds-icon-action-dropbox{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #52aef9));
}

.slds-icon-action-description{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #7dc37d));
}

.slds-icon-action-map{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #76c6ee));
}

.slds-icon-action-user{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #65cae4));
}

.slds-icon-action-reset-password{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #54698d));
}

.slds-icon-action-new-custom50{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #49bcd3));
}

.slds-icon-action-new-custom61{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f57376));
}

.slds-icon-action-new-custom72{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #8d9bfb));
}

.slds-icon-action-new-custom83{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #e7806f));
}

.slds-icon-action-new-custom94{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #439cba));
}

.slds-icon-action-clone{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #6ca1e9));
}

.slds-icon-action-script{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #0070d2));
}

.slds-icon-action-delete{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #e6717c));
}

.slds-icon-action-new-custom40{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #83c75e));
}

.slds-icon-action-new-custom51{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #d8c760));
}

.slds-icon-action-new-custom62{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #6b92dc));
}

.slds-icon-action-new-custom73{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #679ef0));
}

.slds-icon-action-new-custom84{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f6707b));
}

.slds-icon-action-new-custom95{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #8bcf6a));
}

.slds-icon-action-share{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #54698d));
}

.slds-icon-action-new-custom30{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f59f71));
}

.slds-icon-action-new-custom41{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #43b5b5));
}

.slds-icon-action-new-custom52{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #ee8e6f));
}

.slds-icon-action-new-custom63{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #7ccf60));
}

.slds-icon-action-new-custom74{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #41c8a0));
}

.slds-icon-action-new-custom85{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f26891));
}

.slds-icon-action-new-custom96{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #6d9de3));
}

.slds-icon-action-log-event{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #6ca1e9));
}

.slds-icon-action-new-group{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #83b6ff));
}

.slds-icon-action-new-custom20{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #48c7c8));
}

.slds-icon-action-new-custom31{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #eb687f));
}

.slds-icon-action-new-custom42{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #cfd05b));
}

.slds-icon-action-info{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #54698d));
}

.slds-icon-action-new-custom53{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f36e83));
}

.slds-icon-action-new-custom64{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #618fd8));
}

.slds-icon-action-new-custom75{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #cd9f65));
}

.slds-icon-action-new-custom86{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #e260ab));
}

.slds-icon-action-flow{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #0079bc));
}

.slds-icon-action-new-custom97{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #dd6085));
}

.slds-icon-action-submit-for-approval{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #50cc7a));
}

.slds-icon-action-new{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #33bce7));
}

.slds-icon-action-new-campaign{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f49756));
}

.slds-icon-action-new-custom10{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #6488e3));
}

.slds-icon-action-new-custom21{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #8a7aed));
}

.slds-icon-action-new-custom32{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #38c393));
}

.slds-icon-action-new-custom43{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #7f93f9));
}

.slds-icon-action-new-custom54{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #ea70b1));
}

.slds-icon-action-new-custom65{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f279ab));
}

.slds-icon-action-new-custom76{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #db6d7a));
}

.slds-icon-action-new-custom87{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #d876e5));
}

.slds-icon-action-new-custom98{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #e1be5c));
}

.slds-icon-action-new-case{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f2cf5b));
}

.slds-icon-action-new-custom100{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #e15d76));
}

.slds-icon-action-new-custom1{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #ff7b84));
}

.slds-icon-action-new-contact{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #a094ed));
}

.slds-icon-action-office-365{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #ff8041));
}

.slds-icon-action-new-custom11{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #8784ea));
}

.slds-icon-action-new-custom22{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #8b85f9));
}

.slds-icon-action-new-custom33{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #97cf5d));
}

.slds-icon-action-new-custom44{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #c8ca58));
}

.slds-icon-action-new-custom55{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #d66ee0));
}

.slds-icon-action-new-custom66{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #d8be5f));
}

.slds-icon-action-new-custom77{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #b55d5b));
}

.slds-icon-action-new-custom88{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #996fe6));
}

.slds-icon-action-new-custom99{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f0856e));
}

.slds-icon-action-add-contact{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #a094ed));
}

.slds-icon-action-evernote{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #86c86f));
}

.slds-icon-action-new-custom2{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #cfd05c));
}

.slds-icon-action-lead-convert{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f88962));
}

.slds-icon-action-new-custom12{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #dc71d1));
}

.slds-icon-action-new-custom23{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #b070e6));
}

.slds-icon-action-new-custom34{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #d58a6a));
}

.slds-icon-action-new-custom45{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #d95879));
}

.slds-icon-action-new-custom56{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #718deb));
}

.slds-icon-action-new-custom67{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f87d76));
}

.slds-icon-action-recall{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #4a698d));
}

.slds-icon-action-new-custom78{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #5a95dd));
}

.slds-icon-action-new-custom89{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #3e99be));
}

.slds-icon-action-follow{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #31b9f8));
}

.slds-icon-action-record{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #7dc37d));
}

.slds-icon-action-new-custom3{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #ecb46c));
}

.slds-icon-action-new-note{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #e6d478));
}

.slds-icon-action-new-custom13{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #df6184));
}

.slds-icon-action-new-custom24{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #e56798));
}

.slds-icon-action-new-custom35{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #e9637e));
}

.slds-icon-action-new-custom46{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #67a5e7));
}

.slds-icon-action-new-custom57{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #5a9cdd));
}

.slds-icon-action-new-custom68{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f26979));
}

.slds-icon-action-new-custom79{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #8ed363));
}

.slds-icon-action-new-child-case{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #fa975c));
}

.slds-icon-action-new-custom4{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #e1d951));
}

.slds-icon-action-new-custom14{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #3cc2b3));
}

.slds-icon-action-new-custom25{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #e46fbe));
}

.slds-icon-action-new-custom36{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #d472d4));
}

.slds-icon-action-new-custom47{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #5fcc64));
}

.slds-icon-action-new-custom58{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #34b59d));
}

.slds-icon-action-new-custom69{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #ed6387));
}

.slds-icon-action-new-custom5{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #9fdb66));
}

.slds-icon-action-goal{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #56aadf));
}

.slds-icon-action-new-custom15{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f77e75));
}

.slds-icon-action-new-custom26{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #7698f0));
}

.slds-icon-action-new-custom37{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #8c89f2));
}

.slds-icon-action-new-custom48{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #ef697f));
}

.slds-icon-action-new-custom59{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #e3d067));
}

.slds-icon-action-new-custom6{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #54c473));
}

.slds-icon-action-log-this-event{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #eb7092));
}

.slds-icon-action-new-custom16{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #e9af67));
}

.slds-icon-action-new-custom27{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #5ab0d2));
}

.slds-icon-action-new-custom38{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #53b6d7));
}

.slds-icon-action-new-custom49{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #e25c80));
}

.slds-icon-action-new-custom7{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #6a89e5));
}

.slds-icon-action-more{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #62b7ed));
}

.slds-icon-action-add-relationship{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #62b7ed));
}

.slds-icon-action-new-custom17{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #acd360));
}

.slds-icon-action-new-custom28{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #89c059));
}

.slds-icon-action-new-custom39{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #4fbe75));
}

.slds-icon-action-password-unlock{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #54698d));
}

.slds-icon-action-check{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #54698d));
}

.slds-icon-action-update-status{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #1ec7be));
}

.slds-icon-action-preview{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #7f8de1));
}

.slds-icon-action-new-custom8{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #50ceb9));
}

.slds-icon-custom-custom110,
.slds-icon-custom-110{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f28b00));
  color:white;
}

.slds-icon-custom-custom100,
.slds-icon-custom-100{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #e15d76));
  color:white;
}

.slds-icon-custom-custom111,
.slds-icon-custom-111{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f28b00));
  color:white;
}

.slds-icon-custom-custom1,
.slds-icon-custom-1{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #ff7b84));
  color:white;
}

.slds-icon-custom-custom101,
.slds-icon-custom-101{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f28b00));
  color:white;
}

.slds-icon-custom-custom112,
.slds-icon-custom-112{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f28b00));
  color:white;
}

.slds-icon-custom-custom2,
.slds-icon-custom-2{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #cfd05c));
  color:white;
}

.slds-icon-custom-custom102,
.slds-icon-custom-102{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f28b00));
  color:white;
}

.slds-icon-custom-custom113,
.slds-icon-custom-113{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f28b00));
  color:white;
}

.slds-icon-custom-custom90,
.slds-icon-custom-90{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #22a48a));
  color:white;
}

.slds-icon-custom-custom3,
.slds-icon-custom-3{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #ecb46c));
  color:white;
}

.slds-icon-custom-custom103,
.slds-icon-custom-103{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f28b00));
  color:white;
}

.slds-icon-custom-custom80,
.slds-icon-custom-80{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #659ad5));
  color:white;
}

.slds-icon-custom-custom91,
.slds-icon-custom-91{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #bf7b66));
  color:white;
}

.slds-icon-custom-custom4,
.slds-icon-custom-4{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #e1d951));
  color:white;
}

.slds-icon-custom-custom104,
.slds-icon-custom-104{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f28b00));
  color:white;
}

.slds-icon-custom-custom70,
.slds-icon-custom-70{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #e769b4));
  color:white;
}

.slds-icon-custom-custom81,
.slds-icon-custom-81{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #da627f));
  color:white;
}

.slds-icon-custom-custom92,
.slds-icon-custom-92{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #517e82));
  color:white;
}

.slds-icon-custom-custom5,
.slds-icon-custom-5{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #9fdb66));
  color:white;
}

.slds-icon-custom-custom105,
.slds-icon-custom-105{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f28b00));
  color:white;
}

.slds-icon-custom-custom60,
.slds-icon-custom-60{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #bf5a88));
  color:white;
}

.slds-icon-custom-custom71,
.slds-icon-custom-71{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #e36ee3));
  color:white;
}

.slds-icon-custom-custom82,
.slds-icon-custom-82{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #d15b97));
  color:white;
}

.slds-icon-custom-custom93,
.slds-icon-custom-93{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #904d4c));
  color:white;
}

.slds-icon-custom-custom6,
.slds-icon-custom-6{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #54c473));
  color:white;
}

.slds-icon-custom-custom106,
.slds-icon-custom-106{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f28b00));
  color:white;
}

.slds-icon-custom-custom50,
.slds-icon-custom-50{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #49bcd3));
  color:white;
}

.slds-icon-custom-custom61,
.slds-icon-custom-61{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f57376));
  color:white;
}

.slds-icon-custom-custom72,
.slds-icon-custom-72{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #8d9bfb));
  color:white;
}

.slds-icon-custom-custom83,
.slds-icon-custom-83{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #e7806f));
  color:white;
}

.slds-icon-custom-custom94,
.slds-icon-custom-94{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #439cba));
  color:white;
}

.slds-icon-custom-custom7,
.slds-icon-custom-7{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #6a89e5));
  color:white;
}

.slds-icon-custom-custom107,
.slds-icon-custom-107{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f28b00));
  color:white;
}

.slds-icon-custom-custom40,
.slds-icon-custom-40{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #83c75e));
  color:white;
}

.slds-icon-custom-custom51,
.slds-icon-custom-51{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #d8c760));
  color:white;
}

.slds-icon-custom-custom62,
.slds-icon-custom-62{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #6b92dc));
  color:white;
}

.slds-icon-custom-custom73,
.slds-icon-custom-73{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #679ef0));
  color:white;
}

.slds-icon-custom-custom84,
.slds-icon-custom-84{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f6707b));
  color:white;
}

.slds-icon-custom-custom95,
.slds-icon-custom-95{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #8bcf6a));
  color:white;
}

.slds-icon-custom-custom8,
.slds-icon-custom-8{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #50ceb9));
  color:white;
}

.slds-icon-custom-custom108,
.slds-icon-custom-108{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f28b00));
  color:white;
}

.slds-icon-custom-custom30,
.slds-icon-custom-30{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f59f71));
  color:white;
}

.slds-icon-custom-custom41,
.slds-icon-custom-41{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #43b5b5));
  color:white;
}

.slds-icon-custom-custom52,
.slds-icon-custom-52{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #ee8e6f));
  color:white;
}

.slds-icon-custom-custom63,
.slds-icon-custom-63{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #7ccf60));
  color:white;
}

.slds-icon-custom-custom74,
.slds-icon-custom-74{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #41c8a0));
  color:white;
}

.slds-icon-custom-custom85,
.slds-icon-custom-85{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f26891));
  color:white;
}

.slds-icon-custom-custom96,
.slds-icon-custom-96{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #6d9de3));
  color:white;
}

.slds-icon-custom-custom9,
.slds-icon-custom-9{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #6b9ee2));
  color:white;
}

.slds-icon-custom-custom109,
.slds-icon-custom-109{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f28b00));
  color:white;
}

.slds-icon-custom-custom20,
.slds-icon-custom-20{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #48c7c8));
  color:white;
}

.slds-icon-custom-custom31,
.slds-icon-custom-31{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #eb687f));
  color:white;
}

.slds-icon-custom-custom42,
.slds-icon-custom-42{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #cfd05b));
  color:white;
}

.slds-icon-custom-custom53,
.slds-icon-custom-53{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f36e83));
  color:white;
}

.slds-icon-custom-custom64,
.slds-icon-custom-64{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #618fd8));
  color:white;
}

.slds-icon-custom-custom75,
.slds-icon-custom-75{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #cd9f65));
  color:white;
}

.slds-icon-custom-custom86,
.slds-icon-custom-86{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #e260ab));
  color:white;
}

.slds-icon-custom-custom97,
.slds-icon-custom-97{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #dd6085));
  color:white;
}

.slds-icon-custom-custom10,
.slds-icon-custom-10{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #6488e3));
  color:white;
}

.slds-icon-custom-custom21,
.slds-icon-custom-21{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #8a7aed));
  color:white;
}

.slds-icon-custom-custom32,
.slds-icon-custom-32{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #38c393));
  color:white;
}

.slds-icon-custom-custom43,
.slds-icon-custom-43{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #7f93f9));
  color:white;
}

.slds-icon-custom-custom54,
.slds-icon-custom-54{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #ea70b1));
  color:white;
}

.slds-icon-custom-custom65,
.slds-icon-custom-65{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f279ab));
  color:white;
}

.slds-icon-custom-custom76,
.slds-icon-custom-76{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #db6d7a));
  color:white;
}

.slds-icon-custom-custom87,
.slds-icon-custom-87{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #d876e5));
  color:white;
}

.slds-icon-custom-custom98,
.slds-icon-custom-98{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #e1be5c));
  color:white;
}

.slds-icon-custom-custom11,
.slds-icon-custom-11{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #8784ea));
  color:white;
}

.slds-icon-custom-custom22,
.slds-icon-custom-22{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #8b85f9));
  color:white;
}

.slds-icon-custom-custom33,
.slds-icon-custom-33{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #97cf5d));
  color:white;
}

.slds-icon-custom-custom44,
.slds-icon-custom-44{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #c8ca58));
  color:white;
}

.slds-icon-custom-custom55,
.slds-icon-custom-55{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #d66ee0));
  color:white;
}

.slds-icon-custom-custom66,
.slds-icon-custom-66{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #d8be5f));
  color:white;
}

.slds-icon-custom-custom77,
.slds-icon-custom-77{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #b55d5b));
  color:white;
}

.slds-icon-custom-custom88,
.slds-icon-custom-88{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #996fe6));
  color:white;
}

.slds-icon-custom-custom99,
.slds-icon-custom-99{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f0856e));
  color:white;
}

.slds-icon-custom-custom12,
.slds-icon-custom-12{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #dc71d1));
  color:white;
}

.slds-icon-custom-custom23,
.slds-icon-custom-23{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #b070e6));
  color:white;
}

.slds-icon-custom-custom34,
.slds-icon-custom-34{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #d58a6a));
  color:white;
}

.slds-icon-custom-custom45,
.slds-icon-custom-45{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #d95879));
  color:white;
}

.slds-icon-custom-custom56,
.slds-icon-custom-56{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #718deb));
  color:white;
}

.slds-icon-custom-custom67,
.slds-icon-custom-67{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f87d76));
  color:white;
}

.slds-icon-custom-custom78,
.slds-icon-custom-78{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #5a95dd));
  color:white;
}

.slds-icon-custom-custom89,
.slds-icon-custom-89{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #3e99be));
  color:white;
}

.slds-icon-custom-custom13,
.slds-icon-custom-13{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #df6184));
  color:white;
}

.slds-icon-custom-custom24,
.slds-icon-custom-24{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #e56798));
  color:white;
}

.slds-icon-custom-custom35,
.slds-icon-custom-35{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #e9637e));
  color:white;
}

.slds-icon-custom-custom46,
.slds-icon-custom-46{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #67a5e7));
  color:white;
}

.slds-icon-custom-custom57,
.slds-icon-custom-57{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #5a9cdd));
  color:white;
}

.slds-icon-custom-custom68,
.slds-icon-custom-68{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f26979));
  color:white;
}

.slds-icon-custom-custom79,
.slds-icon-custom-79{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #8ed363));
  color:white;
}

.slds-icon-custom-custom14,
.slds-icon-custom-14{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #3cc2b3));
  color:white;
}

.slds-icon-custom-custom25,
.slds-icon-custom-25{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #e46fbe));
  color:white;
}

.slds-icon-custom-custom36,
.slds-icon-custom-36{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #d472d4));
  color:white;
}

.slds-icon-custom-custom47,
.slds-icon-custom-47{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #5fcc64));
  color:white;
}

.slds-icon-custom-custom58,
.slds-icon-custom-58{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #34b59d));
  color:white;
}

.slds-icon-custom-custom69,
.slds-icon-custom-69{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #ed6387));
  color:white;
}

.slds-icon-custom-custom15,
.slds-icon-custom-15{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f77e75));
  color:white;
}

.slds-icon-custom-custom26,
.slds-icon-custom-26{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #7698f0));
  color:white;
}

.slds-icon-custom-custom37,
.slds-icon-custom-37{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #8c89f2));
  color:white;
}

.slds-icon-custom-custom48,
.slds-icon-custom-48{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #ef697f));
  color:white;
}

.slds-icon-custom-custom59,
.slds-icon-custom-59{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #e3d067));
  color:white;
}

.slds-icon-custom-custom16,
.slds-icon-custom-16{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #e9af67));
  color:white;
}

.slds-icon-custom-custom27,
.slds-icon-custom-27{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #5ab0d2));
  color:white;
}

.slds-icon-custom-custom38,
.slds-icon-custom-38{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #53b6d7));
  color:white;
}

.slds-icon-custom-custom49,
.slds-icon-custom-49{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #e25c80));
  color:white;
}

.slds-icon-custom-custom17,
.slds-icon-custom-17{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #acd360));
  color:white;
}

.slds-icon-custom-custom28,
.slds-icon-custom-28{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #89c059));
  color:white;
}

.slds-icon-custom-custom39,
.slds-icon-custom-39{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #4fbe75));
  color:white;
}

.slds-icon-custom-custom18,
.slds-icon-custom-18{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #4dca76));
  color:white;
}

.slds-icon-custom-custom29,
.slds-icon-custom-29{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #bdd25f));
  color:white;
}

.slds-icon-custom-custom19,
.slds-icon-custom-19{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #3abeb1));
  color:white;
}

.slds-icon-standard-task-2{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #4bc076));
}

.slds-icon-standard-contact{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #a094ed));
}

.slds-icon-standard-multi-select-checkbox{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #969492));
}

.slds-icon-standard-work-order{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #50e3c2));
}

.slds-icon-standard-post{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #65cae4));
}

.slds-icon-standard-global-constant{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #54698d));
}

.slds-icon-standard-change-request{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #0b827c));
}

.slds-icon-standard-carousel{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #6bbd6e));
}

.slds-icon-standard-work-contract{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #00a1e0));
}

.slds-icon-standard-resource-skill{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #45c173));
}

.slds-icon-standard-system-and-global-variable{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #54698d));
}

.slds-icon-standard-segments{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f28b00));
}

.slds-icon-standard-account-info{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #7f8de1));
}

.slds-icon-standard-goals{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #56aadf));
}

.slds-icon-standard-tour-check{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #9050e9));
}

.slds-icon-standard-adjust-value{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #47b46c));
}

.slds-icon-standard-case-wrap-up{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f2cf5b));
}

.slds-icon-standard-investment-account{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #4bc076));
}

.slds-icon-standard-store{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #04844b));
}

.slds-icon-standard-slider{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #969492));
}

.slds-icon-standard-output{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #439cba));
}

.slds-icon-standard-medication-ingredient{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #34becd));
}

.slds-icon-standard-store-group{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #3c97dd));
}

.slds-icon-standard-all{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #54698d));
}

.slds-icon-standard-service-territory-policy{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #1589ee));
}

.slds-icon-standard-picklist-choice{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #54698d));
}

.slds-icon-standard-choice{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #54698d));
}

.slds-icon-standard-app{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #fcb95b));
}

.slds-icon-standard-default{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #8199af));
}

.slds-icon-standard-case-milestone{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f2cf5b));
}

.slds-icon-standard-today{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #ef7ead));
}

.slds-icon-standard-buyer-account{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #04844b));
}

.slds-icon-standard-lead-list{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f88962));
}

.slds-icon-standard-work-plan-template{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #449488));
}

.slds-icon-standard-shift{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #eb7092));
}

.slds-icon-standard-product-item-transaction{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f88962));
}

.slds-icon-standard-person-language{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #0eb58a));
}

.slds-icon-standard-apex{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #54698d));
}

.slds-icon-standard-reply-text{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f88965));
}

.slds-icon-standard-answer-private{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f2cf5b));
}

.slds-icon-standard-immunization{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #eb7092));
}

.slds-icon-standard-asset-downtime-period{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #317a92));
}

.slds-icon-standard-opportunity-contact-role{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #7e8be4));
}

.slds-icon-standard-retail-banking-console{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #00afa0));
}

.slds-icon-standard-channel-program-members{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #0eb58a));
}

.slds-icon-standard-apps-admin{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #9895ee));
}

.slds-icon-standard-datadotcom{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #1589ee));
}

.slds-icon-standard-settings{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #04844b));
}

.slds-icon-standard-network-contract{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #6ec06e));
}

.slds-icon-standard-product-item{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #769ed9));
}

.slds-icon-standard-metrics{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #56aadf));
}

.slds-icon-standard-topic2{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #56aad0));
}

.slds-icon-standard-partner-fund-allocation{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #0eb58a));
}

.slds-icon-standard-care-request-reviewer{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #4bc076));
}

.slds-icon-standard-approval{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #50cc7a));
}

.slds-icon-standard-work-queue{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #54698d));
}

.slds-icon-standard-iot-orchestrations{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #2a739e));
}

.slds-icon-standard-visualforce-page{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #fcb95b));
}

.slds-icon-standard-person-account{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #7f8de1));
}

.slds-icon-standard-entity{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f88962));
}

.slds-icon-standard-service-territory-location{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #7e8be4));
}

.slds-icon-standard-qualifications{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #04844b));
}

.slds-icon-standard-entitlement-policy{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #04844b));
}

.slds-icon-standard-form{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #6ec06e));
}

.slds-icon-standard-products{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #04844b));
}

.slds-icon-standard-order-item{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #769ed3));
}

.slds-icon-standard-read-receipts{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #4bc076));
}

.slds-icon-standard-javascript-button{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #fcb95b));
}

.slds-icon-standard-maintenance-asset{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #2a739e));
}

.slds-icon-standard-work-plan-rule{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #449488));
}

.slds-icon-standard-loop{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #ff9a3c));
}

.slds-icon-standard-portal-roles-and-subordinates{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #7a9ae6));
}

.slds-icon-standard-work-capacity-limit{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #0079bc));
}

.slds-icon-standard-employee-organization{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #00a1e0));
}

.slds-icon-standard-workforce-engagement{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #9d3856));
}

.slds-icon-standard-constant{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #54698d));
}

.slds-icon-standard-marketing-actions{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #6bbd6e));
}

.slds-icon-standard-case-transcript{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f2cf5b));
}

.slds-icon-standard-timesheet-entry{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #7dc37d));
}

.slds-icon-standard-key-dates{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #6b9ee2));
}

.slds-icon-standard-multi-picklist{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #969492));
}

.slds-icon-standard-product-workspace{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #04844b));
}

.slds-icon-standard-visit-templates{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #3c97dd));
}

.slds-icon-standard-restriction-policy{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #1589ee));
}

.slds-icon-standard-task{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #4bc076));
}

.slds-icon-standard-answer-best{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f2cf5b));
}

.slds-icon-standard-attach{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #9050e9));
}

.slds-icon-standard-asset-action{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #317a92));
}

.slds-icon-standard-section{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #969492));
}

.slds-icon-standard-service-request{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #1589e4));
}

.slds-icon-standard-orders{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #769ed9));
}

.slds-icon-standard-propagation-policy{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #1589ee));
}

.slds-icon-standard-budget{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #107cad));
}

.slds-icon-standard-report-type{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #45c65a));
}

.slds-icon-standard-past-chat{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f88960));
}

.slds-icon-standard-feedback{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #6da1ea));
}

.slds-icon-standard-action-list-component{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #5876a3));
}

.slds-icon-standard-opportunity-splits{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #fcb95b));
}

.slds-icon-standard-location-permit{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #34b59d));
}

.slds-icon-standard-messaging-user{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #34becd));
}

.slds-icon-standard-filter-criteria{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #2a739e));
}

.slds-icon-standard-trailhead{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #032e61));
}

.slds-icon-standard-entitlements{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #b781d3));
}

.slds-icon-standard-checkout{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #04844b));
}

.slds-icon-standard-formula{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #54698d));
}

.slds-icon-standard-case-log-a-call{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f2cf5b));
}

.slds-icon-standard-shift-pattern-entry{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #eb7092));
}

.slds-icon-standard-thanks-loading{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #b8c3ce));
}

.slds-icon-standard-job-family{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #00a1e0));
}

.slds-icon-standard-service-appointment-capacity-usage{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #0079bc));
}

.slds-icon-standard-channel-program-levels{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #0eb58a));
}

.slds-icon-standard-letterhead{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #3c97dd));
}

.slds-icon-standard-promotions-workspace{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #04844b));
}

.slds-icon-standard-email-chatter{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f2cf5b));
}

.slds-icon-standard-document-reference{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #2a739e));
}

.slds-icon-standard-announcement{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #62b7ed));
}

.slds-icon-standard-field-sales{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #9050e9));
}

.slds-icon-standard-bot{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #54698f));
}

.slds-icon-standard-macros{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #47cfd2));
}

.slds-icon-standard-dashboard-ea{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #7e8be4));
}

.slds-icon-standard-job-profile{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #eb7092));
}

.slds-icon-standard-steps{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #54698d));
}

.slds-icon-standard-asset-relationship{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #fa975c));
}

.slds-icon-standard-high-velocity-sales{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #47cfc9));
}

.slds-icon-standard-brand{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #7e8be4));
}

.slds-icon-standard-historical-adherence{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #03b4a7));
}

.slds-icon-standard-visits{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #3c97dd));
}

.slds-icon-standard-einstein-replies{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f88965));
}

.slds-icon-standard-coaching{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f67594));
}

.slds-icon-standard-record-lookup{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #eb7092));
}

.slds-icon-standard-product-service-campaign-item{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #2a739e));
}

.slds-icon-standard-scheduling-constraint{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #eb7092));
}

.slds-icon-standard-lightning-component{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #969492));
}

.slds-icon-standard-search{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #62b7ed));
}

.slds-icon-standard-connected-apps{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #9895ee));
}

.slds-icon-standard-swarm-request{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #e26e8c));
}

.slds-icon-standard-work-type-group{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #0079bc));
}

.slds-icon-standard-education{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #3c97dd));
}

.slds-icon-standard-work-type{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #54698d));
}

.slds-icon-standard-travel-mode{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #45c173));
}

.slds-icon-standard-environment-hub{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #54698d));
}

.slds-icon-standard-identifier{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f88962));
}

.slds-icon-standard-cms{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #88c651));
}

.slds-icon-standard-call-coaching{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #3c97dd));
}

.slds-icon-standard-salesforce-cms{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #00a1df));
}

.slds-icon-standard-thanks{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #e9696e));
}

.slds-icon-standard-service-territory-member{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #7e8be4));
}

.slds-icon-standard-work-forecast{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #fcb95b));
}

.slds-icon-standard-holiday-operating-hours{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #6b9ee2));
}

.slds-icon-standard-user-role{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #8fc96e));
}

.slds-icon-standard-observation-component{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #7f8de1));
}

.slds-icon-standard-orchestrator{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #006eb3));
}

.slds-icon-standard-work-capacity-usage{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #0079bc));
}

.slds-icon-standard-record-create{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #eb7092));
}

.slds-icon-standard-campaign-members{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f49756));
}

.slds-icon-standard-retail-banking{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #00d2be));
}

.slds-icon-standard-product-service-campaign{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #2a739e));
}

.slds-icon-standard-portal-roles{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #fb8950));
}

.slds-icon-standard-calibration{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #47cfd2));
}

.slds-icon-standard-answer-public{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f2cf5b));
}

.slds-icon-standard-display-text{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #969492));
}

.slds-icon-standard-product-consumed-state{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #06a59a));
}

.slds-icon-standard-unmatched{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #62b7ed));
}

.slds-icon-standard-partners{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #0eb58a));
}

.slds-icon-standard-email-iq{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #a094ed));
}

.slds-icon-standard-service-crew{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #fa975c));
}

.slds-icon-standard-payment-gateway{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #0b827c));
}

.slds-icon-standard-voice-call{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #30c85a));
}

.slds-icon-standard-cancel-checkout{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #969492));
}

.slds-icon-standard-asset-warranty{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #107cad));
}

.slds-icon-standard-resource-capacity{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #45c173));
}

.slds-icon-standard-channel-programs{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #0eb58a));
}

.slds-icon-standard-quip{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #d3451d));
}

.slds-icon-standard-quip-sheet{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #30c85a));
}

.slds-icon-standard-timeslot{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #fab24c));
}

.slds-icon-standard-live-chat{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f88960));
}

.slds-icon-standard-service-request-detail{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #34becd));
}

.slds-icon-standard-med-rec-statement-recommendation{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #808cde));
}

.slds-icon-standard-job-position{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #00a1e0));
}

.slds-icon-standard-sobject-collection{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #54698d));
}

.slds-icon-standard-user{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #34becd));
}

.slds-icon-standard-client{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #00d2be));
}

.slds-icon-standard-screen{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #1589ee));
}

.slds-icon-standard-portal{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #aec770));
}

.slds-icon-standard-partner-fund-request{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #0eb58a));
}

.slds-icon-standard-asset-audit{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #9050e9));
}

.slds-icon-standard-resource-preference{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #45c173));
}

.slds-icon-standard-first-non-empty{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #e9696e));
}

.slds-icon-standard-customer-360{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #032e61));
}

.slds-icon-standard-employee-job{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #00a1e0));
}

.slds-icon-standard-resource-absence{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #45c173));
}

.slds-icon-standard-text-template{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #54698d));
}

.slds-icon-standard-entitlement-template{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #7e8be4));
}

.slds-icon-standard-lightning-usage{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #7e8be4));
}

.slds-icon-standard-product-transfer-state{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #e3734f));
}

.slds-icon-standard-medication-dispense{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #5696d7));
}

.slds-icon-standard-entitlement{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #7e8be4));
}

.slds-icon-standard-empty{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #8199af));
}

.slds-icon-standard-text{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #969492));
}

.slds-icon-standard-delegated-account{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #04844b));
}

.slds-icon-standard-fulfillment-order{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #b9ac91));
}

.slds-icon-standard-case-email{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f2cf5b));
}

.slds-icon-standard-account{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #7f8de1));
}

.slds-icon-standard-coupon-codes{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #04844b));
}

.slds-icon-standard-assignment{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #ff9a3c));
}

.slds-icon-standard-wealth-management-console{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #00afa0));
}

.slds-icon-standard-task2{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #4bc076));
}

.slds-icon-standard-patient-medication-dosage{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #34becd));
}

.slds-icon-standard-code-playground{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #54698d));
}

.slds-icon-standard-social{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #ea74a2));
}

.slds-icon-standard-endorsement{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #8b9ae3));
}

.slds-icon-standard-folder{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #8b9ae3));
}

.slds-icon-standard-service-crew-member{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #7e8be4));
}

.slds-icon-standard-variation-attribute-setup{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #04844b));
}

.slds-icon-standard-flow{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #0079bc));
}

.slds-icon-standard-recipe{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #0176d3));
}

.slds-icon-standard-expense-report-entry{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #3a3180));
}

.slds-icon-standard-employee{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #00a1e0));
}

.slds-icon-standard-omni-supervisor{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #8a76f0));
}

.slds-icon-standard-capacity-plan{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #eb7092));
}

.slds-icon-standard-asset-object{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #317a92));
}

.slds-icon-standard-product{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #b781d3));
}

.slds-icon-standard-topic{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #56aadf));
}

.slds-icon-standard-product-required{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #ef6e64));
}

.slds-icon-standard-dynamic-record-choice{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #54698d));
}

.slds-icon-standard-aggregation-policy{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #1589ee));
}

.slds-icon-standard-warranty-term{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #0079bc));
}

.slds-icon-standard-process{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #0079bc));
}

.slds-icon-standard-swarm-session{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f5675b));
}

.slds-icon-standard-people{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #34becd));
}

.slds-icon-standard-medication{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #4ac076));
}

.slds-icon-standard-reward{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #e9696e));
}

.slds-icon-standard-employee-contact{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #00a1e0));
}

.slds-icon-standard-bundle-policy{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #1589ee));
}

.slds-icon-standard-performance{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f8b156));
}

.slds-icon-standard-promotions{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #04844b));
}

.slds-icon-standard-case-comment{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f2cf5b));
}

.slds-icon-standard-price-book-entries{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #04844b));
}

.slds-icon-standard-sales-channel{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #2a739e));
}

.slds-icon-standard-apex-plugin{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #54698d));
}

.slds-icon-standard-campaign{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f49756));
}

.slds-icon-standard-contact-request{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #fb8954));
}

.slds-icon-standard-business-hours{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #7dc37d));
}

.slds-icon-standard-evernote{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #86c86f));
}

.slds-icon-standard-variation-products{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #04844b));
}

.slds-icon-standard-trailhead-alt{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #00396b));
}

.slds-icon-standard-service-territory{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #7e8be4));
}

.slds-icon-standard-customer-lifecycle-analytics{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #9274df));
}

.slds-icon-standard-code-set{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #0079bc));
}

.slds-icon-standard-case{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f2cf5b));
}

.slds-icon-standard-currency{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #969492));
}

.slds-icon-standard-record{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #7dc37d));
}

.slds-icon-standard-code-set-bundle{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #0079bc));
}

.slds-icon-standard-queue{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #54698d));
}

.slds-icon-standard-schedule-objective{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #2a739e));
}

.slds-icon-standard-contract-line-item{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #6ec06e));
}

.slds-icon-standard-skill-entity{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #8b9ae3));
}

.slds-icon-standard-skill{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #fa975c));
}

.slds-icon-standard-operating-hours{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #6b9ee2));
}

.slds-icon-standard-custom{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #8199af));
}

.slds-icon-standard-related-list{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #59bcab));
}

.slds-icon-standard-bot-training{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #5876a3));
}

.slds-icon-standard-case-change-status{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f2cf5b));
}

.slds-icon-standard-insurance-console{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #00afa0));
}

.slds-icon-standard-incident{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #ba0517));
}

.slds-icon-standard-contract{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #6ec06e));
}

.slds-icon-standard-sobject{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #969492));
}

.slds-icon-standard-sales-cadence-target{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #54698d));
}

.slds-icon-standard-photo{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #d7d1d1));
}

.slds-icon-standard-apps{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #3c97dd));
}

.slds-icon-standard-timesheet{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #7e8be4));
}

.slds-icon-standard-drafts{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #6ca1e9));
}

.slds-icon-standard-outcome{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #ff9a3c));
}

.slds-icon-standard-discounts{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #04844b));
}

.slds-icon-standard-work-order-item{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #33a8dc));
}

.slds-icon-standard-pricebook{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #b781d3));
}

.slds-icon-standard-scan-card{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f39e58));
}

.slds-icon-standard-note{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #e6d478));
}

.slds-icon-standard-serialized-product-transaction{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #1589ee));
}

.slds-icon-standard-shift-pattern{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #eb7092));
}

.slds-icon-standard-buyer-group{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #04844b));
}

.slds-icon-standard-product-quantity-rules{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #04844b));
}

.slds-icon-standard-medication-reconciliation{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #3f719a));
}

.slds-icon-standard-procedure{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #1589e4));
}

.slds-icon-standard-opportunity{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #fcb95b));
}

.slds-icon-standard-news{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #7f8de1));
}

.slds-icon-standard-sort-policy{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #1589ee));
}

.slds-icon-standard-display-rich-text{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #969492));
}

.slds-icon-standard-strategy{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #4bc071));
}

.slds-icon-standard-call-history{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f2cf5b));
}

.slds-icon-standard-webcart{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #04844b));
}

.slds-icon-standard-serialized-product{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #1589ee));
}

.slds-icon-standard-report{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #2ecbbe));
}

.slds-icon-standard-groups{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #779ef2));
}

.slds-icon-standard-data-model{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #eb7092));
}

.slds-icon-standard-dashboard{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #ef6e64));
}

.slds-icon-standard-generic-loading{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #b8c3ce));
}

.slds-icon-standard-number-input{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #969492));
}

.slds-icon-standard-toggle{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #969492));
}

.slds-icon-standard-address{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #4bc076));
}

.slds-icon-standard-price-books{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #04844b));
}

.slds-icon-standard-entity-milestone{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f49756));
}

.slds-icon-standard-wealth-management{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #00d2be));
}

.slds-icon-standard-customers{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #0eb58a));
}

.slds-icon-standard-story{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #54698d));
}

.slds-icon-standard-service-appointment{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #7e8be4));
}

.slds-icon-standard-maintenance-plan{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #2a739e));
}

.slds-icon-standard-data-integration-hub{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #2a739e));
}

.slds-icon-standard-person-name{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #7f8de1));
}

.slds-icon-standard-work-plan-template-entry{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #449488));
}

.slds-icon-standard-hierarchy{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #34becd));
}

.slds-icon-standard-custom-component-task{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #8a76f0));
}

.slds-icon-standard-partner-marketing-budget{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #0eb58a));
}

.slds-icon-standard-asset-action-source{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #317a92));
}

.slds-icon-standard-skill-requirement{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #fa975c));
}

.slds-icon-standard-filter-criteria-rule{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #2a739e));
}

.slds-icon-standard-location{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #4bc076));
}

.slds-icon-standard-radio-button{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #969492));
}

.slds-icon-standard-avatar-loading{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #b8c3ce));
}

.slds-icon-standard-article{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f2cf5b));
}

.slds-icon-standard-invocable-action{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #54698d));
}

.slds-icon-standard-device{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #7f8de1));
}

.slds-icon-standard-proposition{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #3c97dd));
}

.slds-icon-standard-snippets{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #0eb58a));
}

.slds-icon-standard-customer-portal-users{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #3c97db));
}

.slds-icon-standard-actions-and-buttons{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #fcb95b));
}

.slds-icon-standard-record-update{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #eb7092));
}

.slds-icon-standard-shift-type{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #eb7092));
}

.slds-icon-standard-log-a-call{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #48c3cc));
}

.slds-icon-standard-quotes{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #88c651));
}

.slds-icon-standard-question-feed{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f2cf5b));
}

.slds-icon-standard-kanban{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #3c97dd));
}

.slds-icon-standard-work-plan{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #449488));
}

.slds-icon-standard-shift-template{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #eb7092));
}

.slds-icon-standard-merge{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f2cf5b));
}

.slds-icon-standard-expense{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #3a3180));
}

.slds-icon-standard-dataset{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #b070e6));
}

.slds-icon-standard-shift-preference{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #eb7092));
}

.slds-icon-standard-product-consumed{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #55bc9c));
}

.slds-icon-standard-canvas{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #8199af));
}

.slds-icon-standard-forecasts{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #6bbd6e));
}

.slds-icon-standard-relationship{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #3c97dd));
}

.slds-icon-standard-tour{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #9050e9));
}

.slds-icon-standard-service-resource{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #7e8be4));
}

.slds-icon-standard-filter{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #1539ee));
}

.slds-icon-standard-sales-path{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #2a739e));
}

.slds-icon-standard-shift-scheduling-operation{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #eb7092));
}

.slds-icon-standard-events{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #3c97db));
}

.slds-icon-standard-data-mapping{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #061c3f));
}

.slds-icon-standard-sms{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #88c651));
}

.slds-icon-standard-rtc-presence{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #47cfd2));
}

.slds-icon-standard-avatar{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #62b7ed));
}

.slds-icon-standard-record-delete{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #eb7092));
}

.slds-icon-standard-solution{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #8fc972));
}

.slds-icon-standard-agent-home{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #00396b));
}

.slds-icon-standard-partner-fund-claim{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #0eb58a));
}

.slds-icon-standard-individual{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #3c97dd));
}

.slds-icon-standard-custom-notification{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #6bb7e4));
}

.slds-icon-standard-contract-payment{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #9050e9));
}

.slds-icon-standard-date-input{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #969492));
}

.slds-icon-standard-catalog{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #027e46));
}

.slds-icon-standard-template{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #3c97dd));
}

.slds-icon-standard-activation-target{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #dc71d1));
}

.slds-icon-standard-selling-model{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #ad7bee));
}

.slds-icon-standard-shipment{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #7e8be4));
}

.slds-icon-standard-budget-allocation{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #107cad));
}

.slds-icon-standard-record-signature-task{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #eb7092));
}

.slds-icon-standard-event{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #eb7092));
}

.slds-icon-standard-insurance{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #00d2be));
}

.slds-icon-standard-live-chat-visitor{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f68960));
}

.slds-icon-standard-textarea{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #969492));
}

.slds-icon-standard-work-step{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #449488));
}

.slds-icon-standard-picklist-type{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #969492));
}

.slds-icon-standard-survey{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #319fd6));
}

.slds-icon-standard-link{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #7a9ae6));
}

.slds-icon-standard-messaging-session{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #34becd));
}

.slds-icon-standard-video{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #b070e6));
}

.slds-icon-standard-list-email{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #8baeb5));
}

.slds-icon-standard-med-rec-recommendation{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #61bbca));
}

.slds-icon-standard-recycle-bin{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #1589e4));
}

.slds-icon-standard-document{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #baac93));
}

.slds-icon-standard-product-transfer{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #e3734f));
}

.slds-icon-standard-instore-locations{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #54698d));
}

.slds-icon-standard-recent{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #6ca1e9));
}

.slds-icon-standard-practitioner-role{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #eb7092));
}

.slds-icon-standard-password{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #969492));
}

.slds-icon-standard-expense-report{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #3a3180));
}

.slds-icon-standard-branch-merge{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #e9696e));
}

.slds-icon-standard-insights{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #ec94ed));
}

.slds-icon-standard-dropbox{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #52aef9));
}

.slds-icon-standard-unified-health-score{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #7e8be4));
}

.slds-icon-standard-calculated-insights{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #54a7f8));
}

.slds-icon-standard-employee-job-position{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #00a1e0));
}

.slds-icon-standard-file{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #baac93));
}

.slds-icon-standard-currency-input{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #969492));
}

.slds-icon-standard-variable{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #54698d));
}

.slds-icon-standard-team-member{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f2cf5b));
}

.slds-icon-standard-pricing-workspace{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #04844b));
}

.slds-icon-standard-agent-session{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f88960));
}

.slds-icon-standard-guidance-center{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #7f8de1));
}

.slds-icon-standard-group-loading{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #b8c3ce));
}

.slds-icon-standard-lead{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f88962));
}

.slds-icon-standard-email{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #95aec5));
}

.slds-icon-standard-service-contract{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #8a76f0));
}

.slds-icon-standard-decision{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #ff9a3c));
}

.slds-icon-standard-promotion-segments{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #04844b));
}

.slds-icon-standard-duration-downscale{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #1589ee));
}

.slds-icon-standard-snippet{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #a094ed));
}

.slds-icon-standard-entitlement-process{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #7e8be4));
}

.slds-icon-standard-contact-list{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #a094ed));
}

.slds-icon-standard-planogram{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #3c97dd));
}

.slds-icon-standard-activations{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #b070e6));
}

.slds-icon-standard-scheduling-policy{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #eb7092));
}

.slds-icon-standard-channel-program-history{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #0eb58a));
}

.slds-icon-standard-question-best{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f2cf5b));
}

.slds-icon-standard-collection-variable{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #54698d));
}

.slds-icon-standard-procedure-detail{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #34becd));
}

.slds-icon-standard-sales-value{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #3c97dd));
}

.slds-icon-standard-knowledge{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #ec94ed));
}

.slds-icon-standard-date-time{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #969492));
}

.slds-icon-standard-category{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #027e46));
}

.slds-icon-standard-maintenance-work-rule{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #2a739e));
}

.slds-icon-standard-data-streams{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #ef6e64));
}

.slds-icon-standard-textbox{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #969492));
}

.slds-icon-standard-lead-insights{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #22b0e6));
}

.slds-icon-standard-waits{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #ff9a3c));
}

.slds-icon-standard-concur{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #4cc3c7));
}

.slds-icon-standard-feed{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #62b7ed));
}

.slds-icon-standard-sort{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #1539ee));
}

.slds-icon-standard-messaging-conversation{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #34becd));
}

.slds-icon-standard-service-report{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #7e8be4));
}

.slds-icon-standard-iot-context{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #2a739e));
}

.slds-icon-standard-asset-state-period{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #317a92));
}

.slds-icon-standard-call{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f2cf5b));
}

.slds-icon-standard-collection{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f2cf5b));
}

.slds-icon-standard-stage{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #ff9a3c));
}

.slds-icon-standard-sales-cadence{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #54698d));
}

.slds-icon-standard-product-request-line-item{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #88c651));
}

.slds-icon-standard-return-order-line-item{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #009688));
}

.slds-icon-standard-chart{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #1fcaa0));
}

.slds-icon-standard-quick-text{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #62b7e5));
}

.slds-icon-standard-home{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #ef7ead));
}

.slds-icon-standard-sossession{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #54698d));
}

.slds-icon-standard-bundle-config{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #1589ee));
}

.slds-icon-standard-employee-asset{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #00a1e0));
}

.slds-icon-standard-process-exception{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #f5a623));
}

.slds-icon-standard-product-warranty-term{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #b781d3));
}

.slds-icon-standard-problem{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #fe9339));
}

.slds-icon-standard-stage-collection{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #ff9a3c));
}

.slds-icon-standard-product-request{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #88c651));
}

.slds-icon-standard-logging{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #00a1df));
}

.slds-icon-standard-assigned-resource{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #45c173));
}

.slds-icon-standard-return-order{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #009688));
}

.slds-icon-standard-poll{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #699be1));
}

.slds-icon-standard-household{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #00afa0));
}

.slds-icon-standard-work-step-template{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #449488));
}

.slds-icon-standard-medication-statement{
  background-color:var(--slds-c-icon-color-background, var(--sds-c-icon-color-background, #1589ee));
}
.slds-icon{
  width:2rem;
  height:2rem;
  fill:var(--slds-c-icon-color-foreground, var(--sds-c-icon-color-foreground, white));
}
.slds-icon_xx-small,
.slds-icon--xx-small{
  width:0.875rem;
  height:0.875rem;
  line-height:1;
}
.slds-icon_x-small,
.slds-icon--x-small{
  width:1rem;
  height:1rem;
  line-height:1;
}
.slds-icon_small,
.slds-icon--small{
  width:1.5rem;
  height:1.5rem;
  line-height:1;
}
.slds-icon_large,
.slds-icon--large{
  width:3rem;
  height:3rem;
}
.slds-icon-text-default{
  /*! @css-var-fallback fill */
  --slds-c-icon-color-foreground:var(--slds-c-icon-color-foreground-default, var(--sds-c-icon-color-foreground-default, #747474));
}
.slds-icon-text-default.slds-is-disabled{
  fill:#c9c9c9;
}
.slds-icon-text-warning{
  /*! @css-var-fallback fill */
  --slds-c-icon-color-foreground:#fe9339;
}
.slds-icon-text-success{
  /*! @css-var-fallback fill */
  --slds-c-icon-color-foreground:#2e844a;
}
.slds-icon-text-error{
  /*! @css-var-fallback fill */
  --slds-c-icon-color-foreground:#ea001e;
}
.slds-icon-text-light{
  /*! @css-var-fallback fill */
  --slds-c-icon-color-foreground:#aeaeae;
}
.slds-current-color .slds-icon{
  /*! @css-var-fallback fill */
  --slds-c-icon-color-foreground:currentColor;
}
.slds-icon_disabled{
  background-color:currentColor;
}
.slds-accordion{
  position:relative;
}
.slds-accordion__list-item{
  border-top-width:var(--slds-c-accordion-sizing-border, var(--sds-c-accordion-sizing-border, 1px));
  border-top-style:solid;
  border-top-color:var(--slds-c-accordion-color-border, var(--sds-c-accordion-color-border, #e5e5e5));
}
.slds-accordion__list-item:first-child{
  border-top:0;
}
.slds-accordion__summary{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  margin-top:calc( var(--slds-c-accordion-section-spacing-block-start, var(--sds-c-accordion-section-spacing-block-start, 0.75rem * -1)));
  margin-right:calc( var(--slds-c-accordion-section-spacing-inline-end, var(--sds-c-accordion-section-spacing-inline-end, 0.75rem * -1)));
  margin-bottom:calc( var(--slds-c-accordion-section-spacing-block-end, var(--sds-c-accordion-section-spacing-block-end, 0.75rem * -1)));
  margin-left:calc( var(--slds-c-accordion-section-spacing-inline-start, var(--sds-c-accordion-section-spacing-inline-start, 0.75rem * -1)));
  padding-top:var(--slds-c-accordion-section-spacing-block-start, var(--sds-c-accordion-section-spacing-block-start, 0.75rem));
  padding-right:var(--slds-c-accordion-section-spacing-inline-end, var(--sds-c-accordion-section-spacing-inline-end, 0.75rem));
  padding-bottom:var(--slds-c-accordion-section-spacing-block-end, var(--sds-c-accordion-section-spacing-block-end, 0.75rem));
  padding-left:var(--slds-c-accordion-section-spacing-inline-start, var(--sds-c-accordion-section-spacing-inline-start, 0.75rem));
  background-color:var(--slds-c-accordion-summary-color-background, var(--sds-c-accordion-summary-color-background, transparent));
}
.slds-accordion__summary-heading{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-flex:1;
      -ms-flex-positive:1;
          flex-grow:1;
  min-width:0;
  color:var(--slds-c-accordion-heading-text-color, var(--sds-c-accordion-heading-text-color, #181818));
  font-size:var(--slds-c-accordion-heading-font-size, var(--sds-c-accordion-heading-font-size, 1rem));
  line-height:var(--slds-c-accordion-heading-line-height, var(--sds-c-accordion-heading-line-height, 1.25));
}
.slds-accordion__summary-heading .slds-button:focus{
  text-decoration:underline;
  -webkit-box-shadow:none;
          box-shadow:none;
}
.slds-accordion__summary-action{
  display:-webkit-inline-box;
  display:-ms-inline-flexbox;
  display:inline-flex;
  -webkit-box-flex:1;
      -ms-flex-positive:1;
          flex-grow:1;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  min-width:0;
  margin-top:calc( var(--slds-c-accordion-section-spacing-block-start, var(--sds-c-accordion-section-spacing-block-start, 0.75rem * -1)));
  margin-right:calc( var(--slds-c-accordion-section-spacing-inline-end, var(--sds-c-accordion-section-spacing-inline-end, 0.75rem * -1)));
  margin-bottom:calc( var(--slds-c-accordion-section-spacing-block-end, var(--sds-c-accordion-section-spacing-block-end, 0.75rem * -1)));
  margin-left:calc( var(--slds-c-accordion-section-spacing-inline-start, var(--sds-c-accordion-section-spacing-inline-start, 0.75rem * -1)));
  padding-top:var(--slds-c-accordion-section-spacing-block-start, var(--sds-c-accordion-section-spacing-block-start, 0.75rem));
  padding-right:var(--slds-c-accordion-section-spacing-inline-end, var(--sds-c-accordion-section-spacing-inline-end, 0.75rem));
  padding-bottom:var(--slds-c-accordion-section-spacing-block-end, var(--sds-c-accordion-section-spacing-block-end, 0.75rem));
  padding-left:var(--slds-c-accordion-section-spacing-inline-start, var(--sds-c-accordion-section-spacing-inline-start, 0.75rem));
}
.slds-accordion__summary-action:hover, .slds-accordion__summary-action:focus{
  color:var(--sds-c-accordion-heading-text-color-hover, var(--sds-c-accordion-heading-text-color-hover, #014486));
}
.slds-accordion__summary-action-icon{
  -ms-flex-negative:0;
      flex-shrink:0;
  -webkit-transform:rotate(-90deg);
          transform:rotate(-90deg);
}
[dir=rtl] .slds-accordion__summary-action-icon{
  -webkit-transform:rotate(90deg);
          transform:rotate(90deg);
}
@media (min-width: 64em){
  .slds-accordion__summary-content{
    max-width:100%;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
  }
}
.slds-accordion__section{
  padding-top:var(--slds-c-accordion-section-spacing-block-start, var(--sds-c-accordion-section-spacing-block-start, 0.75rem));
  padding-right:var(--slds-c-accordion-section-spacing-inline-end, var(--sds-c-accordion-section-spacing-inline-end, 0.75rem));
  padding-bottom:var(--slds-c-accordion-section-spacing-block-end, var(--sds-c-accordion-section-spacing-block-end, 0.75rem));
  padding-left:var(--slds-c-accordion-section-spacing-inline-start, var(--sds-c-accordion-section-spacing-inline-start, 0.75rem));
}
.slds-accordion__content{
  overflow:hidden;
  visibility:hidden;
  opacity:0;
  height:0;
}
.slds-is-open > .slds-accordion__summary{
  margin-bottom:0;
}
.slds-is-open > .slds-accordion__summary .slds-accordion__summary-action-icon{
  -webkit-transform:rotate(0deg);
          transform:rotate(0deg);
}
.slds-is-open > .slds-accordion__content{
  overflow:visible;
  visibility:visible;
  opacity:1;
  height:auto;
}
.slds-icon-ellie{
  width:calc(14rem / 16);
  height:calc(14rem / 16);
  display:inline-block;
  overflow:hidden;
}
.slds-icon-ellie.slds-is-animated{
  will-change:transform;
  -webkit-animation:slds-icon-ellie-pop 0.3s ease-in 2s 1 both;
          animation:slds-icon-ellie-pop 0.3s ease-in 2s 1 both;
}
.slds-icon-ellie.slds-is-animated svg{
  will-change:transform;
  -webkit-animation:slds-icon-ellie 1.2s steps(20) 2.3s 2 alternate;
          animation:slds-icon-ellie 1.2s steps(20) 2.3s 2 alternate;
}
.slds-icon-ellie.slds-is-paused,
.slds-icon-ellie.slds-is-paused svg{
  -webkit-animation-play-state:paused;
          animation-play-state:paused;
}
.slds-icon-ellie svg{
  width:17.5rem;
  vertical-align:top;
  color:#0d9dda;
}
.slds-icon-ellie circle:nth-child(2n+1){
  fill:currentColor;
  stroke:currentColor;
  stroke-width:4px;
}
.slds-icon-ellie circle:nth-child(2n){
  fill:white;
  stroke:white;
  stroke-width:1px;
}

.slds-icon-ellie circle:nth-child(1),
.slds-icon-ellie circle:nth-last-child(2){
  stroke-width:4px;
}

.slds-icon-ellie circle:nth-child(2),
.slds-icon-ellie circle:nth-last-child(1){
  stroke-width:1px;
}

.slds-icon-ellie circle:nth-child(3),
.slds-icon-ellie circle:nth-last-child(4){
  stroke-width:3.95px;
}

.slds-icon-ellie circle:nth-child(4),
.slds-icon-ellie circle:nth-last-child(3){
  stroke-width:1.2px;
}

.slds-icon-ellie circle:nth-child(5),
.slds-icon-ellie circle:nth-last-child(6){
  stroke-width:3.85px;
}

.slds-icon-ellie circle:nth-child(6),
.slds-icon-ellie circle:nth-last-child(5){
  stroke-width:1.4px;
}

.slds-icon-ellie circle:nth-child(7),
.slds-icon-ellie circle:nth-last-child(8){
  stroke-width:3.6px;
}

.slds-icon-ellie circle:nth-child(8),
.slds-icon-ellie circle:nth-last-child(7){
  stroke-width:1.7px;
}

.slds-icon-ellie circle:nth-child(9),
.slds-icon-ellie circle:nth-last-child(10){
  stroke-width:3.3px;
}

.slds-icon-ellie circle:nth-child(10),
.slds-icon-ellie circle:nth-last-child(9){
  stroke-width:2px;
}

.slds-icon-ellie circle:nth-child(11),
.slds-icon-ellie circle:nth-last-child(12){
  stroke-width:3.2px;
}

.slds-icon-ellie circle:nth-child(12),
.slds-icon-ellie circle:nth-last-child(11){
  stroke-width:2.4px;
}

.slds-icon-ellie circle:nth-child(13),
.slds-icon-ellie circle:nth-last-child(14){
  stroke-width:3.15px;
}

.slds-icon-ellie circle:nth-child(14),
.slds-icon-ellie circle:nth-last-child(13){
  stroke-width:2.8px;
}

.slds-icon-ellie circle:nth-child(15),
.slds-icon-ellie circle:nth-last-child(16){
  stroke-width:3.1px;
}

.slds-icon-ellie circle:nth-child(16),
.slds-icon-ellie circle:nth-last-child(15){
  stroke-width:3.25px;
}

.slds-icon-ellie circle:nth-child(17),
.slds-icon-ellie circle:nth-last-child(18){
  stroke-width:3.05px;
}

.slds-icon-ellie circle:nth-child(18),
.slds-icon-ellie circle:nth-last-child(17){
  stroke-width:3.7px;
}

.slds-icon-ellie circle:nth-child(19),
.slds-icon-ellie circle:nth-last-child(20){
  stroke-width:3px;
}

.slds-icon-ellie circle:nth-child(20),
.slds-icon-ellie circle:nth-last-child(19){
  stroke-width:4px;
}

@-webkit-keyframes slds-icon-ellie-pop{
  0%{
    -webkit-transform:scale(0.2);
            transform:scale(0.2);
  }
  70%{
    -webkit-transform:scale(1.1);
            transform:scale(1.1);
  }
  90%{
    -webkit-transform:scale(0.7);
            transform:scale(0.7);
  }
  100%{
    -webkit-transform:scale(1);
            transform:scale(1);
  }
}

@keyframes slds-icon-ellie-pop{
  0%{
    -webkit-transform:scale(0.2);
            transform:scale(0.2);
  }
  70%{
    -webkit-transform:scale(1.1);
            transform:scale(1.1);
  }
  90%{
    -webkit-transform:scale(0.7);
            transform:scale(0.7);
  }
  100%{
    -webkit-transform:scale(1);
            transform:scale(1);
  }
}
@-webkit-keyframes slds-icon-ellie{
  to{
    -webkit-transform:translateX(-17.5rem);
            transform:translateX(-17.5rem);
  }
}
@keyframes slds-icon-ellie{
  to{
    -webkit-transform:translateX(-17.5rem);
            transform:translateX(-17.5rem);
  }
}
.slds-icon-eq{
  position:relative;
  width:calc(14rem / 16);
  height:calc(14rem / 16);
}
.slds-icon-eq.slds-is-animated .slds-icon-eq__bar{
  -webkit-animation:slds-icon-eq 0.25s ease-in-out infinite alternate;
          animation:slds-icon-eq 0.25s ease-in-out infinite alternate;
  will-change:transform;
  height:0.1875rem;
}
.slds-icon-eq.slds-is-animated .slds-icon-eq__bar:nth-of-type(2){
  -webkit-animation-duration:0.65s;
          animation-duration:0.65s;
}
.slds-icon-eq.slds-is-animated .slds-icon-eq__bar:nth-of-type(3){
  -webkit-animation-duration:0.35s;
          animation-duration:0.35s;
}
.slds-icon-eq__bar{
  position:absolute;
  bottom:0;
  left:0;
  width:calc(4rem / 16);
  height:0.5625rem;
  background:#0176d3;
  -webkit-transform:scaleY(1);
          transform:scaleY(1);
  -webkit-transform-origin:bottom;
          transform-origin:bottom;
}
.slds-icon-eq__bar:nth-of-type(2){
  left:calc(5rem / 16);
  height:0.875rem;
}
.slds-icon-eq__bar:nth-of-type(3){
  left:calc(10rem / 16);
  height:0.75rem;
}

@-webkit-keyframes slds-icon-eq{
  to{
    -webkit-transform:scaleY(4.6666666667);
            transform:scaleY(4.6666666667);
  }
}

@keyframes slds-icon-eq{
  to{
    -webkit-transform:scaleY(4.6666666667);
            transform:scaleY(4.6666666667);
  }
}
.slds-icon-score{
  display:inline-block;
  width:0.3125rem;
  height:0.3125rem;
  position:relative;
}

[class*=slds-icon-score__]{
  position:absolute;
  opacity:0;
  -webkit-transition:opacity 0.4s ease-in-out;
  transition:opacity 0.4s ease-in-out;
  will-change:opacity;
  stroke-width:1.125px;
}
.slds-icon-score__positive{
  fill:#2e844a;
  stroke:#2e844a;
}
.slds-icon-score__negative{
  fill:transparent;
  stroke:#ba0517;
}

[data-slds-state=positive] .slds-icon-score__positive,
[data-slds-state=negative] .slds-icon-score__negative{
  opacity:1;
}
.slds-icon-strength{
  width:1.6875rem;
  height:0.4375rem;
  display:inline-block;
}
.slds-icon-strength circle{
  stroke-width:0.95px;
  fill:transparent;
  stroke:#939393;
  -webkit-transition:fill 0.4s ease-in-out, stroke 0.4s ease-in-out;
  transition:fill 0.4s ease-in-out, stroke 0.4s ease-in-out;
  will-change:fill, stroke;
}
.slds-icon-strength[data-slds-strength="1"] circle:nth-child(1), .slds-icon-strength[data-slds-strength="2"] circle:nth-child(1), .slds-icon-strength[data-slds-strength="2"] circle:nth-child(2), .slds-icon-strength[data-slds-strength="3"] circle:nth-child(1), .slds-icon-strength[data-slds-strength="3"] circle:nth-child(2), .slds-icon-strength[data-slds-strength="3"] circle:nth-child(3){
  fill:#2e844a;
  stroke:#2e844a;
}
.slds-icon-strength[data-slds-strength="-1"] circle:nth-child(1), .slds-icon-strength[data-slds-strength="-2"] circle:nth-child(1), .slds-icon-strength[data-slds-strength="-2"] circle:nth-child(2), .slds-icon-strength[data-slds-strength="-3"] circle:nth-child(1), .slds-icon-strength[data-slds-strength="-3"] circle:nth-child(2), .slds-icon-strength[data-slds-strength="-3"] circle:nth-child(3){
  fill:#fddde3;
  stroke:#ba0517;
}
.slds-icon-strength.slds-is-animated circle{
  -webkit-animation:slds-icon-strength-positive-load 0.4s 1s ease-in-out alternate both paused;
          animation:slds-icon-strength-positive-load 0.4s 1s ease-in-out alternate both paused;
}
.slds-icon-strength.slds-is-animated circle:nth-child(2){
  -webkit-animation-delay:1.4s;
          animation-delay:1.4s;
}
.slds-icon-strength.slds-is-animated circle:nth-child(3){
  -webkit-animation-delay:1.8s;
          animation-delay:1.8s;
}
.slds-icon-strength.slds-is-animated[data-slds-strength^="-"] circle{
  -webkit-animation-name:slds-icon-strength-negative-load;
          animation-name:slds-icon-strength-negative-load;
}
.slds-icon-strength.slds-is-animated[data-slds-strength="-1"] circle:nth-child(1), .slds-icon-strength.slds-is-animated[data-slds-strength="-2"] circle:nth-child(1), .slds-icon-strength.slds-is-animated[data-slds-strength="-2"] circle:nth-child(2), .slds-icon-strength.slds-is-animated[data-slds-strength="-3"] circle:nth-child(1), .slds-icon-strength.slds-is-animated[data-slds-strength="-3"] circle:nth-child(2), .slds-icon-strength.slds-is-animated[data-slds-strength="-3"] circle:nth-child(3), .slds-icon-strength.slds-is-animated[data-slds-strength="1"] circle:nth-child(1), .slds-icon-strength.slds-is-animated[data-slds-strength="2"] circle:nth-child(1), .slds-icon-strength.slds-is-animated[data-slds-strength="2"] circle:nth-child(2), .slds-icon-strength.slds-is-animated[data-slds-strength="3"] circle:nth-child(1), .slds-icon-strength.slds-is-animated[data-slds-strength="3"] circle:nth-child(2), .slds-icon-strength.slds-is-animated[data-slds-strength="3"] circle:nth-child(3){
  -webkit-animation-play-state:running;
          animation-play-state:running;
}
.slds-icon-strength.slds-is-paused circle{
  -webkit-animation-play-state:paused !important;
          animation-play-state:paused !important;
}

@-webkit-keyframes slds-icon-strength-positive-load{
  0%{
    fill:transparent;
    stroke:#939393;
  }
  100%{
    fill:#2e844a;
    stroke:#2e844a;
  }
}

@keyframes slds-icon-strength-positive-load{
  0%{
    fill:transparent;
    stroke:#939393;
  }
  100%{
    fill:#2e844a;
    stroke:#2e844a;
  }
}
@-webkit-keyframes slds-icon-strength-negative-load{
  0%{
    fill:transparent;
    stroke:#939393;
  }
  100%{
    fill:#fddde3;
    stroke:#ba0517;
  }
}
@keyframes slds-icon-strength-negative-load{
  0%{
    fill:transparent;
    stroke:#939393;
  }
  100%{
    fill:#fddde3;
    stroke:#ba0517;
  }
}
.slds-icon-trend{
  width:1rem;
  height:1rem;
  display:inline-block;
}
.slds-icon-trend.slds-is-animated .slds-icon-trend__arrow{
  -webkit-animation:slds-icon-trend-arrow 0.8s 0.4s ease-in-out both;
          animation:slds-icon-trend-arrow 0.8s 0.4s ease-in-out both;
}
.slds-icon-trend.slds-is-animated .slds-icon-trend__circle{
  -webkit-animation:slds-icon-trend-circle 0.8s ease-in-out both;
          animation:slds-icon-trend-circle 0.8s ease-in-out both;
}
.slds-icon-trend.slds-is-paused .slds-icon-trend__arrow,
.slds-icon-trend.slds-is-paused .slds-icon-trend__circle{
  -webkit-animation-play-state:paused;
          animation-play-state:paused;
}

.slds-icon-trend[data-slds-trend=down]{
  color:#ba0517;
  -webkit-transform:rotate(45deg);
          transform:rotate(45deg);
}

.slds-icon-trend[data-slds-trend=neutral]{
  color:#939393;
}

.slds-icon-trend[data-slds-trend=up]{
  color:#2e844a;
  -webkit-transform:rotate(-45deg);
          transform:rotate(-45deg);
}

.slds-icon-trend__arrow,
.slds-icon-trend__circle{
  stroke:currentColor;
  fill:none;
  stroke-linecap:round;
  stroke-linejoin:round;
  stroke-width:1.125px;
}
.slds-icon-trend__arrow{
  stroke-dashoffset:0.1px;
  stroke-dasharray:39.175px;
}
.slds-icon-trend__circle{
  stroke-dasharray:42.3385px, 46.3385px;
}

@-webkit-keyframes slds-icon-trend-arrow{
  from{
    stroke-dashoffset:40.3px;
  }
}

@keyframes slds-icon-trend-arrow{
  from{
    stroke-dashoffset:40.3px;
  }
}
@-webkit-keyframes slds-icon-trend-circle{
  from{
    stroke-dasharray:0.1px, 46.3385px;
  }
}
@keyframes slds-icon-trend-circle{
  from{
    stroke-dasharray:0.1px, 46.3385px;
  }
}
.slds-icon-waffle_container{
  border:0;
  outline:0;
}
.slds-icon-waffle_container:hover [class*=slds-r], .slds-icon-waffle_container:focus [class*=slds-r]{
  -webkit-animation:slds-icon-waffle-throb 2 200ms alternate;
          animation:slds-icon-waffle-throb 2 200ms alternate;
}
.slds-icon-waffle_container:hover .slds-r1,
.slds-icon-waffle_container:hover .slds-r2,
.slds-icon-waffle_container:hover .slds-r3, .slds-icon-waffle_container:focus .slds-r1,
.slds-icon-waffle_container:focus .slds-r2,
.slds-icon-waffle_container:focus .slds-r3{
  background-color:#418fde;
}
.slds-icon-waffle_container:hover .slds-r4,
.slds-icon-waffle_container:hover .slds-r5,
.slds-icon-waffle_container:hover .slds-r7, .slds-icon-waffle_container:focus .slds-r4,
.slds-icon-waffle_container:focus .slds-r5,
.slds-icon-waffle_container:focus .slds-r7{
  background-color:#ed8b00;
}
.slds-icon-waffle_container:hover .slds-r6,
.slds-icon-waffle_container:hover .slds-r8,
.slds-icon-waffle_container:hover .slds-r9, .slds-icon-waffle_container:focus .slds-r6,
.slds-icon-waffle_container:focus .slds-r8,
.slds-icon-waffle_container:focus .slds-r9{
  background-color:#ffb60f;
}
.slds-icon-waffle_container:hover .slds-r2,
.slds-icon-waffle_container:hover .slds-r4, .slds-icon-waffle_container:focus .slds-r2,
.slds-icon-waffle_container:focus .slds-r4{
  -webkit-transition-delay:50ms;
          transition-delay:50ms;
  -webkit-animation-delay:50ms;
          animation-delay:50ms;
}
.slds-icon-waffle_container:hover .slds-r3,
.slds-icon-waffle_container:hover .slds-r5,
.slds-icon-waffle_container:hover .slds-r7, .slds-icon-waffle_container:focus .slds-r3,
.slds-icon-waffle_container:focus .slds-r5,
.slds-icon-waffle_container:focus .slds-r7{
  -webkit-transition-delay:100ms;
          transition-delay:100ms;
  -webkit-animation-delay:100ms;
          animation-delay:100ms;
}
.slds-icon-waffle_container:hover .slds-r6,
.slds-icon-waffle_container:hover .slds-r8, .slds-icon-waffle_container:focus .slds-r6,
.slds-icon-waffle_container:focus .slds-r8{
  -webkit-transition-delay:150ms;
          transition-delay:150ms;
  -webkit-animation-delay:150ms;
          animation-delay:150ms;
}
.slds-icon-waffle_container:hover .slds-r9, .slds-icon-waffle_container:focus .slds-r9{
  -webkit-transition-delay:200ms;
          transition-delay:200ms;
  -webkit-animation-delay:200ms;
          animation-delay:200ms;
}
.slds-icon-waffle{
  width:1.3125rem;
  height:1.3125rem;
  position:relative;
  display:block;
  cursor:pointer;
}
.slds-icon-waffle [class*=slds-r]{
  width:0.3125rem;
  height:0.3125rem;
  background-color:#747474;
  display:inline-block;
  position:absolute;
  border-radius:50%;
  -webkit-transition:background-color 100ms;
  transition:background-color 100ms;
  -webkit-transform-origin:50% 50%;
          transform-origin:50% 50%;
  will-change:scale, background-color;
}
.slds-icon-waffle .slds-r1{
  top:0;
  left:0;
}
.slds-icon-waffle .slds-r2{
  top:0;
  left:0.5rem;
}
.slds-icon-waffle .slds-r3{
  top:0;
  right:0;
}
.slds-icon-waffle .slds-r4{
  top:0.5rem;
  left:0;
}
.slds-icon-waffle .slds-r5{
  top:0.5rem;
  left:0.5rem;
}
.slds-icon-waffle .slds-r6{
  top:0.5rem;
  right:0;
}
.slds-icon-waffle .slds-r7{
  bottom:0;
  left:0;
}
.slds-icon-waffle .slds-r8{
  bottom:0;
  left:0.5rem;
}
.slds-icon-waffle .slds-r9{
  bottom:0;
  right:0;
}
.slds-icon-waffle .slds-r1{
  -webkit-transition-delay:200ms;
          transition-delay:200ms;
}
.slds-icon-waffle .slds-r2,
.slds-icon-waffle .slds-r4{
  -webkit-transition-delay:150ms;
          transition-delay:150ms;
}
.slds-icon-waffle .slds-r3,
.slds-icon-waffle .slds-r5,
.slds-icon-waffle .slds-r7{
  -webkit-transition-delay:100ms;
          transition-delay:100ms;
}
.slds-icon-waffle .slds-r6,
.slds-icon-waffle .slds-r8{
  -webkit-transition-delay:50ms;
          transition-delay:50ms;
}

@-webkit-keyframes slds-icon-waffle-throb{
  to{
    -webkit-transform:scale(1.5);
            transform:scale(1.5);
  }
}

@keyframes slds-icon-waffle-throb{
  to{
    -webkit-transform:scale(1.5);
            transform:scale(1.5);
  }
}
.slds-icon-help{
  width:1.5rem;
  height:1.5rem;
  fill:#aeaeae;
}
.slds-icon-help:hover .slds-icon-help_hover{
  opacity:1;
  -webkit-transform:scale(1);
          transform:scale(1);
}
.slds-icon-help:focus{
  -webkit-animation:slds-click-global-action 80ms cubic-bezier(1, 1.9, 0.94, 0.98);
          animation:slds-click-global-action 80ms cubic-bezier(1, 1.9, 0.94, 0.98);
}
.slds-icon-help:focus .slds-icon-help_focus{
  opacity:1;
  -webkit-transform:scale(1);
          transform:scale(1);
}
.slds-icon-help mask{
  mask-type:alpha;
}
.slds-icon-help_hover{
  opacity:0;
  -webkit-transform:scale(0.1, 0.1);
          transform:scale(0.1, 0.1);
  -webkit-transform-origin:45px 47px;
          transform-origin:45px 47px;
  -webkit-transition:all 200ms ease-out;
  transition:all 200ms ease-out;
  fill:#014486;
}
.slds-icon-help_focus{
  opacity:0;
  -webkit-transform:scale(0.1, 0.1);
          transform:scale(0.1, 0.1);
  -webkit-transform-origin:45px 47px;
          transform-origin:45px 47px;
  -webkit-transition:all 200ms ease-out;
  transition:all 200ms ease-out;
  fill:#0176d3;
}

.slds-icon-help svg{
  width:1.5rem;
  height:1.5rem;
}

.slds-icon-help g{
  -webkit-mask:url(#questionMark);
          mask:url(#questionMark);
}

@-webkit-keyframes slds-click-global-action{
  25%{
    -webkit-transform:scale(0.95, 0.95);
            transform:scale(0.95, 0.95);
  }
  100%{
    -webkit-transform:scale(0.98, 0.98);
            transform:scale(0.98, 0.98);
  }
}

@keyframes slds-click-global-action{
  25%{
    -webkit-transform:scale(0.95, 0.95);
            transform:scale(0.95, 0.95);
  }
  100%{
    -webkit-transform:scale(0.98, 0.98);
            transform:scale(0.98, 0.98);
  }
}
.slds-icon-typing{
  display:inline-block;
  padding:0.25rem 0;
}
.slds-icon-typing.slds-is-animated .slds-icon-typing__dot{
  -webkit-animation:slds-icon-typing__dot-first 1.2s infinite ease-in-out;
          animation:slds-icon-typing__dot-first 1.2s infinite ease-in-out;
}
.slds-icon-typing.slds-is-animated .slds-icon-typing__dot:nth-child(1){
  -webkit-animation-name:slds-icon-typing__dot-first;
          animation-name:slds-icon-typing__dot-first;
}
.slds-icon-typing.slds-is-animated .slds-icon-typing__dot:nth-child(2){
  -webkit-animation-name:slds-icon-typing__dot-second;
          animation-name:slds-icon-typing__dot-second;
}
.slds-icon-typing.slds-is-animated .slds-icon-typing__dot:nth-child(3){
  -webkit-animation-name:slds-icon-typing__dot-third;
          animation-name:slds-icon-typing__dot-third;
}
.slds-icon-typing.slds-is-paused .slds-icon-typing__dot{
  -webkit-animation-play-state:paused;
          animation-play-state:paused;
}
.slds-icon-typing__dot{
  background-color:#c9c9c9;
  border-radius:50%;
  display:inline-block;
  height:0.5rem;
  margin:0 0.125rem;
  width:0.5rem;
}

